<template>
    <div class="customizeForm">
        <el-form ref="batchForm" :label-width="formLabelWidth" :model="batchForm" :rules="batchRules">
            <template v-for="item in field_list" :key="item.cate">
                <el-divider>{{item.cate}}</el-divider>
                <el-form-item v-for="son in item.list" :key="son.id" :prop="son.key"
                              :hide-required-asterisk="son.is_required != 1">
                    <template v-slot:label>
                        <span style="color: #F56C6C" v-if="son.is_required == 1">*</span>
                        {{son.name}}
                    </template>
                    <template v-if="son.type == 'text'">
                        <el-input v-model="batchForm[son.key]" v-if="son.key == 'age'"
                                  style="width: 214px;" :disabled="son.disabled" readonly></el-input>
                        <el-input v-model="batchForm[son.key]" v-else-if="son.key == 'sex'"
                                  style="width: 214px;" :disabled="son.disabled" readonly></el-input>
                        <el-input v-model="batchForm[son.key]" v-else-if="son.key == 'id_card'"
                                  style="width: 214px;" :disabled="son.disabled"></el-input>
                        <el-input v-model="batchForm[son.key]" v-else
                                  style="width: 214px;" :disabled="son.disabled"></el-input>
                    </template>
                    <el-select v-model="batchForm[son.key]" filterable v-if="son.type == 'select'">
                        <el-option v-for="item in son.options" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                    <el-upload v-if="son.type == 'image'" style="width: 400px;" ref="importUpload" multiple
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="importForm['image_importHandleChange_' + son.key]"
                               :before-remove="importForm['image_importHandleRemove_' + son.key]"
                               :file-list="importForm['image_importFileList_' + son.key]"
                               :auto-upload="false"
                               :on-exceed="importForm['image_importHandleExceed_' + son.key]"
                               list-type="picture" accept="image/png,image/jpg,image/jpeg"
                               :limit="importForm['image_importFileLimit_' + son.key]">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请上传jpg、jpeg、png格式图片，大小不能超过1M</div>
                        </template>
                    </el-upload>
                    <el-date-picker v-model="batchForm[son.key]" v-if="son.type == 'datetime'" type="date"
                                    value-format="YYYY-MM-DD" style="width: 214px;"></el-date-picker>
                </el-form-item>
            </template>
        </el-form>
    </div>
</template>

<script>

    import {utilsUploadFile} from '../utils/utils.js'

    import Global from '../Global.js'

    export default {
        name: "customizeForm",
        data() {
            let check_id_card = (rule, value, callback) => {
                if (Global.GlobalShenfenzhengReg.test(value)) {
                    console.log(123);
                    callback()
                }
                else {
                    callback(new Error('身份证号格式有误'))
                }
            }
            let check_phone = (rule, value, callback) => {
                if (Global.GlobalMobileReg.test(value)) {
                    console.log(123);
                    callback()
                }
                else {
                    callback(new Error('手机号格式有误'))
                }
            }
            return {
                field_list: [],
                importForm: [],
                batchForm: [],
                batchFormInitialData: {
                    text: '',
                    select: '',
                    image: []
                },
                disabledList: [],
                formLabelWidth: 'auto',
                batchRules:{
                    id_card: [{ validator: check_id_card , trigger: 'change' }],
                    phone: [{ validator: check_phone , trigger: 'change' }],
                }
            }
        },
        props: {
            fieldData: [Array],
            selMsg: [Object],
            labelWidth: [String]
        },
        watch: {
            "batchForm.id_card"() {
                this.idCardChange();
            },
            fieldData() {
                this.dealWith()
            },
            labelWidth() {
                if(this.labelWidth){
                    this.formLabelWidth = this.labelWidth;
                }
            }
        },
        created() {
            this.dealWith()
        },
        methods: {
            idCardChange() {
                this.batchForm.age = ''
                this.batchForm.sex = ''
                if (!Global.GlobalShenfenzhengReg.test(this.batchForm.id_card)) {
                    return
                }
                if (!this.checkSfzh(this.batchForm.id_card)) {
                    return
                }
                this.batchForm.age = this.returnSfzhAge(this.batchForm.id_card)
                this.batchForm.sex = this.returnSfzhSex(this.batchForm.id_card)
            },
            returnSfzhAge(identityCard) {
                var len = (identityCard + "").length;
                if (len == 0) {
                    return 0;
                }
                else {
                    if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
                    {
                        return 0;
                    }
                }
                var strBirthday = "";
                if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
                {
                    strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
                }
                if (len == 15) {
                    strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
                }
                //时间字符串里，必须是“/”
                var birthDate = new Date(strBirthday);
                var nowDateTime = new Date();
                var age = nowDateTime.getFullYear() - birthDate.getFullYear();
                //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
                if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age;
            },
            returnSfzhSex(sfzh) {
                var str2 = sfzh;
                var num = str2.charAt(16);
                if (num % 2 == 0) {
                    return '女'
                }
                else {
                    return '男'
                }
            },
            checkSfzh(sfzh) {
                let index_array = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let sfzh_array = new Array();
                let total = 0;
                let last_number;
                let start = 0;
                let end = 1;
                for (let i = 0; i < sfzh.length - 1; i++) {
                    let temp = sfzh.slice(start + i, end + i);
                    sfzh_array.push(temp);
                }
                for (let i = 0; i < index_array.length; i++) {
                    let temp = sfzh_array[i] * index_array[i];
                    total = total + temp;
                }
                switch (total % 11) {
                    case 0:
                        last_number = 1;
                        break;
                    case 1:
                        last_number = 0;
                        break;
                    case 2:
                        last_number = 'X';
                        break
                    case 3:
                        last_number = 9;
                        break;
                    case 4:
                        last_number = 8
                        break;
                    case 5:
                        last_number = 7;
                        break;
                    case 6:
                        last_number = 6;
                        break;
                    case 7:
                        last_number = 5;
                        break;
                    case 8:
                        last_number = 4;
                        break;
                    case 9:
                        last_number = 3
                        break;
                    case 10:
                        last_number = 2;
                        break;
                }
                if (sfzh.slice(17, 18) == last_number) {
                    return true;
                }
                else {
                    return false;
                }
            },
            getFormData() {
                let data = {};
                for (let i = 0; i < this.field_list.length; i++) {
                    let item = this.field_list[i]
                    for (let j = 0; j < item.list.length; j++) {
                        let son = item.list[j];
                        if (son.is_required == 1) {
                            if (this.batchForm[son.key] == '' || this.batchForm[son.key] == null) {
                                this.$message({
                                    showClose: true,
                                    message: son.name + ' 为必填项',
                                    type: 'warning'
                                });
                                return
                            }
                            if (son.key == 'id_card') {
                                if (!Global.GlobalShenfenzhengReg.test(this.batchForm[son.key])) {
                                    this.$message({
                                        showClose: true,
                                        message: '身份证号 格式有误',
                                        type: 'warning'
                                    });
                                    return
                                }
                                if (!this.checkSfzh(this.batchForm[son.key])) {
                                    this.$message({
                                        showClose: true,
                                        message: '身份证号 验证不通过，请检查',
                                        type: 'warning'
                                    });
                                    return
                                }
                            }
                            else if (son.key == 'phone') {
                                if (!Global.GlobalMobileReg.test(this.batchForm[son.key])) {
                                    this.$message({
                                        showClose: true,
                                        message: '手机号 格式有误',
                                        type: 'warning'
                                    });
                                    return
                                }
                            }
                        }
                        if (!son.disabled) {
                            data[son.key] = this.batchForm[son.key];
                        }
                    }
                }
                return data
            },
            dealWith() {
                if (this.fieldData) {
                    let that = this;
                    if(this.labelWidth){
                        this.formLabelWidth = this.labelWidth;
                    }
                    let field_list = JSON.parse(JSON.stringify(this.fieldData));
                    field_list.forEach(item => {
                        item.list.forEach(son => {
                            if (this.selMsg && son.key && this.selMsg[son.key]) {
                                this.batchForm[son.key] = this.selMsg[son.key];
                            }
                            else {
                                this.batchForm[son.key] = this.batchFormInitialData[son.type]
                            }
                            if (son.type == 'select' && son.def_value != null) {
                                let options = [];
                                son.def_value.forEach(item2 => {
                                    options.push({
                                        value: item2,
                                        label: item2
                                    })
                                })
                                son.options = options;
                            }
                            if (son.type == 'image') {
                                let imageList = [];
                                if (this.selMsg) {
                                    let one = {
                                        name: son.name,
                                        url: this.selMsg[son.key + '_image'],
                                        file_id: this.selMsg[son.key],
                                    }
                                    imageList.push(one)
                                }
                                that.importForm['image_importFileList_' + son.key] = imageList;
                                that.importForm['image_importFileLimit_' + son.key] = 1;
                                that.importForm['image_importHandleExceed_' + son.key] = function (files, fileList) {
                                    that.$message.warning(`当前限制选择 ` + that.importForm['image_importFileLimit_' + son.key] + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
                                }
                                that.importForm['image_importHandleChange_' + son.key] = function (file, fileList) {
                                    if (file.status == "ready") {
                                        let uid = file.uid
                                        const isLt = file.size / 1024 / 1024 / 1 <= 1;
                                        if (isLt) {
                                            utilsUploadFile(file.raw, 'import').then(res => {
                                                if (res) {
                                                    file.file_id = res.file_id;
                                                    let newArray = [];
                                                    fileList.forEach(item => {
                                                        newArray.push(item.file_id)
                                                    })
                                                    that.batchForm[son.key] = newArray.join(',')
                                                }
                                            })
                                        }
                                        else {
                                            that.$message.warning("上传文件大小不得大于1MB!");
                                            for (let i = 0; i < fileList.length; i++) {
                                                if (fileList[i].uid == uid) {
                                                    fileList.splice(i, 1)
                                                }
                                            }
                                            let newArray = [];
                                            fileList.forEach(item => {
                                                newArray.push(item.file_id)
                                            })
                                            that.batchForm[son.key] = newArray.join(',')
                                        }
                                    }
                                }
                                that.importForm['image_importHandleRemove_' + son.key] = function (file, fileList) {
                                    let uid = file.uid;
                                    for (let i = 0; i < fileList.length; i++) {
                                        if (fileList[i].uid == uid) {
                                            fileList.splice(i, 1)
                                        }
                                    }
                                    let newArray = [];
                                    fileList.forEach(item => {
                                        newArray.push(item.file_id)
                                    })
                                    that.batchForm[son.key] = newArray.join(',')
                                }
                            }
                        })
                    })
                    this.field_list = field_list;
                }
            }
        }
    }
</script>

<style scoped>

    .customizeForm {
        width: 100%;
        box-sizing: border-box;
    }

</style>
